<template>
  <div>
    <div class="cardHead">
      {{ $g("termsAndConditions") }}
    </div>
    <div class="cardBody">
      <k-alert-msg ref="lblMsg" />
      <KValidationObserver ref="observer">
        <k-form
          @keydown.enter="isPrevent"
          @submit.prevent="save"
          :disabled="isDisable"
        >
          <KValidationProvider name="checked" v-slot="{ errors }" slim>
            <k-alert :show="errors.length > 0" variant="warning">
              {{ errors[0] }}
            </k-alert>
            <k-alert :show="checked == false" variant="warning">
              {{ $g("required") }}
            </k-alert>
            <k-form-checkbox v-model="checked"> agreeContent </k-form-checkbox>
          </KValidationProvider>
          <k-form-group
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            class="wapBtnCenter"
          >
            <div style="margin-top: 30px">
              <k-button
                @click="backAction"
                variant="utility"
                @keydown.enter="backAction"
                class="w-150"
                style="margin-right: 80px"
              >
                back
              </k-button>
              <k-button
                @click="nextAction"
                variant="primary"
                class="w-150"
                @keydown.enter="nextAction"
                :disabled="isDisable"
              >
                preview
              </k-button>
            </div>
          </k-form-group>
        </k-form>
      </KValidationObserver>
    </div>
  </div>
</template>

<script>
import { isPrevent } from "fe-infrastractures";
export default {
  data() {
    return {
      isPrevent,
      checked: this.termsChecked,
      isDisable: false,
    };
  },
  props: {
    termsChecked: {
      type: Boolean,
      default() {
        return null;
      },
    },
  },
  model: { prop: "termsChecked" },
  methods: {
    backAction() {
      this.$emit("back-action", 2);
    },
    nextAction() {
      if (this.checked == null) {
        this.checked = false;
      } else if (this.checked) {
        this.isDisable = true;
        setTimeout(() => {
          this.isDisable = false;
        }, 1000);
        this.$emit("next-action", 2);
      }
    },
  },
  watch: {
    checked: {
      handler: function () {
        this.$emit("input", this.checked);
      },
      deep: true,
    },
  },
};
</script>

