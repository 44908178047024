var render = function render(){var _vm=this,_c=_vm._self._c;return _c('TimeLine',{attrs:{"cur-index":_vm.curIndex,"id":"timeLine"},scopedSlots:_vm._u([{key:"topModule",fn:function(){return [_c('TopInfo',{ref:"topInfo",attrs:{"other-i-d":"topInfo","id":_vm.$route.query.id,"page-name":_vm.$route.query.pagename,"title":_vm.topTitle,"sub-title":_vm.$route.query.title}})]},proxy:true}])},[[(_vm.curIndex === 0)?_c('div',[_c('k-alert-msg',{ref:"lblMsg"}),_c('KValidationObserver',{ref:"observer"},[_c('k-form',{attrs:{"disabled":_vm.isDisable},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.isPrevent.apply(null, arguments)},"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"layoutCard"},[_c('div',{staticClass:"cardBody",staticStyle:{"display":"flex","flex-direction":"column"}},[_c('div',{staticClass:"cardHead"},[_vm._v(" "+_vm._s(_vm.$g("personalData"))+" ")]),(_vm.isFieldShow(_vm.workerFieldNameEnums.photo))?_c('k-form-group',{style:({
                  order: _vm.getOrderIndex(_vm.workerFieldNameEnums.photo),
                }),attrs:{"id":"photoImg","label-suffix":"photo","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.photo)
                    ? 'require-mark'
                    : ''}},[_c('div',{staticClass:"d-flex flex-wrap justify-content-between"},[_c('div',{staticClass:"mr-2"},[_c('k-img',{staticStyle:{"max-height":"100px"},attrs:{"src":_vm.form.photoPath}})],1),_c('div',{staticClass:"d-flex flex-wrap align-items-start mt-2"},[(!_vm.isFieldViewOnly(_vm.workerFieldNameEnums.photo))?_c('k-button',{staticClass:"mb-1 mr-2",on:{"click":_vm.changePhoto}},[_vm._v(" buttons.upload ")]):_vm._e(),(!_vm.isFieldViewOnly(_vm.workerFieldNameEnums.photo))?_c('k-button',{staticClass:"mb-1",attrs:{"variant":"utility"},on:{"click":_vm.removePhoto}},[_vm._v(" buttons.remove ")]):_vm._e()],1)])]):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.jobCode))?_c('k-form-group',{style:({
                  order: _vm.getOrderIndex(_vm.workerFieldNameEnums.jobCode),
                }),attrs:{"label-suffix":"jobCode","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.jobCode)
                    ? 'require-mark'
                    : ''}},[_c('KValidationProvider',{attrs:{"rules":{
                    required: _vm.isFieldRequired(_vm.workerFieldNameEnums.jobCode),
                    max: 200,
                    remote: _vm.isJobCodeUsed,
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{attrs:{"plaintext":_vm.jobCodeAuto ||
                      _vm.isFieldViewOnly(_vm.workerFieldNameEnums.jobCode),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.jobCode)},model:{value:(_vm.form.jobCode),callback:function ($$v) {_vm.$set(_vm.form, "jobCode", $$v)},expression:"form.jobCode"}})]}}],null,false,3727349578)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.firstName))?_c('k-form-group',{style:({
                  order: _vm.getOrderIndex(_vm.workerFieldNameEnums.firstName),
                }),attrs:{"id":"firstNameInput","label-suffix":"firstName","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.firstName)
                    ? 'require-mark'
                    : ''}},[_c('KValidationProvider',{attrs:{"name":"firstName","rules":{
                    required: _vm.isFieldRequired(_vm.workerFieldNameEnums.firstName),
                    max: 200,
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{attrs:{"id":"firstName","aria-label":_vm.$g('firstName'),"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.firstName),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.firstName)},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}})]}}],null,false,488406797)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.middleName))?_c('k-form-group',{style:({
                  order: _vm.getOrderIndex(_vm.workerFieldNameEnums.middleName),
                }),attrs:{"id":"middleNameInput","label-suffix":"middleName","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.middleName)
                    ? 'require-mark'
                    : ''}},[_c('KValidationProvider',{attrs:{"name":"middleName","rules":{
                    required: _vm.isFieldRequired(
                      _vm.workerFieldNameEnums.middleName
                    ),
                    max: 200,
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{attrs:{"id":"middleName","aria-label":_vm.$g('middleName'),"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.middleName),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.middleName)},model:{value:(_vm.form.middleName),callback:function ($$v) {_vm.$set(_vm.form, "middleName", $$v)},expression:"form.middleName"}})]}}],null,false,2899913306)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.lastName))?_c('k-form-group',{style:({
                  order: _vm.getOrderIndex(_vm.workerFieldNameEnums.lastName),
                }),attrs:{"id":"lastNameInput","label-suffix":"lastName","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.lastName)
                    ? 'require-mark'
                    : ''}},[_c('KValidationProvider',{attrs:{"name":"lastName","rules":{
                    required: _vm.isFieldRequired(_vm.workerFieldNameEnums.lastName),
                    max: 200,
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{attrs:{"id":"lastName","aria-label":_vm.$g('lastName'),"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.lastName),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.lastName)},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}})]}}],null,false,2582615485)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.suffix))?_c('k-form-group',{style:({
                  order: _vm.getOrderIndex(_vm.workerFieldNameEnums.suffix),
                }),attrs:{"label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.suffix)
                    ? 'require-mark'
                    : '',"id":"suffixInput","label-suffix":"Suffix","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
                    required: _vm.isFieldRequired(_vm.workerFieldNameEnums.suffix),
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.suffixOptions,"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.suffix),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.suffix)},model:{value:(_vm.form.suffix),callback:function ($$v) {_vm.$set(_vm.form, "suffix", $$v)},expression:"form.suffix"}})]}}],null,false,863463943)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.dob))?_c('k-form-group',{style:({
                  order: _vm.getOrderIndex(_vm.workerFieldNameEnums.dob),
                }),attrs:{"id":"dobDatepicker","label-suffix":"dob","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-for":"noneBind","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.dob)
                    ? 'require-mark'
                    : ''}},[_c('KValidationProvider',{attrs:{"name":"dob","rules":{
                    required: _vm.isFieldRequired(_vm.workerFieldNameEnums.dob),
                    validateDateFormat: true,
                    validateDateIsRequired: _vm.isFieldRequired(
                      _vm.workerFieldNameEnums.dob
                    ),
                    remote: _vm.checkAge,
                    dob: true,
                  },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-datepicker',{attrs:{"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.dob),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.dob)},model:{value:(_vm.form.dob),callback:function ($$v) {_vm.$set(_vm.form, "dob", $$v)},expression:"form.dob"}})]}}],null,false,2656243234)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.yob))?_c('k-form-group',{style:({
                  order: _vm.getOrderIndex(_vm.workerFieldNameEnums.yob),
                }),attrs:{"id":"yobDatepicker","label-suffix":"yearOfBirth","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-for":"noneBind","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.yob)
                    ? 'require-mark'
                    : ''}},[_c('KValidationProvider',{attrs:{"name":"yob","rules":{
                    required: _vm.isFieldRequired(_vm.workerFieldNameEnums.yob),
                    validateDateFormat: true,
                    validateDateIsRequired: _vm.isFieldRequired(
                      _vm.workerFieldNameEnums.yob
                    ),
                    remote: _vm.checkAge,
                    dob: true,
                  },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.dobOptions,"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.yob),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.yob)},model:{value:(_vm.form.dob),callback:function ($$v) {_vm.$set(_vm.form, "dob", $$v)},expression:"form.dob"}})]}}],null,false,3112311335)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.partyId))?_c('k-form-group',{style:({
                  order: _vm.getOrderIndex(_vm.workerFieldNameEnums.partyId),
                }),attrs:{"id":"partySelect","label-suffix":"party","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-for":"noneBind","label-class":_vm.politicalPartyRequired &&
                  _vm.isFieldRequired(_vm.workerFieldNameEnums.partyId)
                    ? 'require-mark'
                    : ''}},[_c('KValidationProvider',{attrs:{"rules":{
                    required:
                      _vm.politicalPartyRequired &&
                      _vm.isFieldRequired(_vm.workerFieldNameEnums.partyId),
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.partyId),"options":_vm.partys,"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.partyId)},model:{value:(_vm.form.politicalPartyId),callback:function ($$v) {_vm.$set(_vm.form, "politicalPartyId", $$v)},expression:"form.politicalPartyId"}})]}}],null,false,1941076992)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.ssn))?_c('b-form-group',{style:({
                  order: _vm.getOrderIndex(_vm.workerFieldNameEnums.ssn),
                }),attrs:{"label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.ssn)
                    ? 'require-mark'
                    : '',"label-suffix":"sSN","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","id":"ssnTooltip"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{class:_vm.getConfigVal(
                        _vm.curPageConfigAndDefaultPageConfig,
                        'ssnTooltip',
                        'labelClass'
                      ),staticStyle:{"margin-right":"6px"}},[_vm._v(_vm._s(_vm.$g("sSN"))+": ")]),(
                      _vm.getConfigVal(
                        _vm.curPageConfigAndDefaultPageConfig,
                        'ssnTooltipIcon',
                        'vIf'
                      )
                    )?_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
                      placement: 'righttop',
                      title: _vm.$g('SSNDescription'),
                      boundary: 'window',
                      container: 'ssnTooltip',
                    }),expression:"{\n                      placement: 'righttop',\n                      title: $g('SSNDescription'),\n                      boundary: 'window',\n                      container: 'ssnTooltip',\n                    }"}],attrs:{"icon":"info-circle-fill"}}):_vm._e()]},proxy:true}],null,false,3115867075)},[_c('KValidationProvider',{attrs:{"rules":_vm.ssnDigits == 9
                      ? {
                          required:
                            _vm.isFieldRequired(_vm.workerFieldNameEnums.ssn) &&
                            !_vm.isShowPassword
                              ? true
                              : false,
                          ssnLength: 9,
                          ssn: {
                            firstBanNumber: ['000', '666'],
                            firstBanNumberBetween: [900, 999],
                            middleNumberBetween: [0, 100],
                            lastnNumberBetween: [0, 10000],
                          },
                          remote: _vm.isSSNUsed,
                        }
                      : {
                          required:
                            _vm.isFieldRequired(_vm.workerFieldNameEnums.ssn) &&
                            !_vm.isShowPassword
                              ? true
                              : false,
                          ssnLength_4: {
                            numberBetween: [0, 10000],
                          },
                        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('KSSN',{attrs:{"ssn-digits":_vm.ssnDigits,"props-show-password":true,"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.ssn),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.ssn)},model:{value:(_vm.form.ssn),callback:function ($$v) {_vm.$set(_vm.form, "ssn", $$v)},expression:"form.ssn"}})]}}],null,false,876061894)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.raceId))?_c('k-form-group',{style:({
                  order: _vm.getOrderIndex(_vm.workerFieldNameEnums.raceId),
                }),attrs:{"id":"raceSelect","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.raceId)
                    ? 'require-mark'
                    : '',"label-suffix":"whatsYourRace","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-for":"noneBind"}},[_c('KValidationProvider',{attrs:{"rules":{
                    required: _vm.isFieldRequired(_vm.workerFieldNameEnums.raceId),
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.raceOptions,"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.raceId),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.raceId)},model:{value:(_vm.form.raceId),callback:function ($$v) {_vm.$set(_vm.form, "raceId", $$v)},expression:"form.raceId"}})]}}],null,false,3895319466)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.residentialAddress))?_c('div',{staticClass:"cardHead",style:({
                  order: _vm.getOrderIndex(
                    _vm.workerFieldNameEnums.residentialAddress
                  ),
                })},[_vm._v(" "+_vm._s(_vm.$g("residentialAddress"))+" "),(_vm.needAddressDescription)?_c('div',{staticClass:"addressDescription",attrs:{"id":"residentialAddressDiv"}},[_vm._v(" "+_vm._s(_vm.$g("residentialAddressDescription"))+" ")]):_vm._e()]):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.residentialAddress))?_c('address-form',{staticClass:"cardBody addressHandle",style:({
                  order: _vm.getOrderIndex(
                    _vm.workerFieldNameEnums.residentialAddress
                  ),
                }),attrs:{"id":"residentialAddress","identifier":"residential","address-label":"residentialAddress","address":_vm.residentialAddressForm,"street-types":_vm.streetTypes,"state-types":_vm.stateTypes,"street-posts":_vm.streetPosts,"loading":_vm.loading,"addressformat":_vm.addressformat,"validate":true,"is-show-overseas-address":false,"overseas-validate":"","apt-address-form-blur-event-native-address":_vm.onAptHandle,"street-name-placeholder":"application.streetNamePlaceholder","is-plain-text":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.residentialAddress)},on:{"addressNativeChanged":_vm.addressNativeChanged}}):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.postalAddress))?_c('div',{staticClass:"cardHead",style:({
                  order: _vm.getOrderIndex(_vm.workerFieldNameEnums.postalAddress),
                })},[_vm._v(" "+_vm._s(_vm.$g("postalAddress"))+" "),(_vm.needAddressDescription)?_c('div',{staticClass:"addressDescription",attrs:{"id":"postalAddressDiv"}},[_vm._v(" "+_vm._s(_vm.$g("postalAddressDescription1"))+" "+_vm._s(_vm.$g("postalAddressDescription2"))+" ")]):_vm._e()]):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.postalAddress))?_c('address-form-overseas',{ref:"addressFormOverseas",staticClass:"cardBody addressHandle",style:({
                  order: _vm.getOrderIndex(_vm.workerFieldNameEnums.postalAddress),
                }),attrs:{"id":"postalAddress","is-plain-text":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.postalAddress),"identifier":"postal","address-label":"postalAddress","address":_vm.postalAddressForm,"loading":_vm.loading,"address-copy-label":"sara","address-to-copy":_vm.residentialAddressForm,"overseas-validate":false,"mailing-address-format":_vm.mailingaddressformat,"has-oversea-country":_vm.getConfigVal(
                    _vm.curPageConfigAndDefaultPageConfig,
                    'addressFormOverseasOverseaCountry',
                    'vIf'
                  ) &&
                  _vm.mailingaddressformat.find(
                    (it) => it.name === 'AddressLine4'
                  ) != null}}):_vm._e(),(!_vm.isHideStudentFields)?_c('div',{staticClass:"cardHead",style:({
                  order: _vm.getOrderIndex(_vm.workerFieldNameEnums.languageIdList),
                })},[_vm._v(" "+_vm._s(_vm.$g("applicationDetails"))+" ")]):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.languageIdList))?_c('k-form-group',{style:({
                  order: _vm.getOrderIndex(_vm.workerFieldNameEnums.languageIdList),
                }),attrs:{"label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.languageIdList)
                    ? 'require-mark'
                    : '',"id":"languageSpokenMultiselect","label-suffix":"languageSpoken","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
                    required: _vm.isFieldRequired(
                      _vm.workerFieldNameEnums.languageIdList
                    ),
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-multiselect',{attrs:{"options":_vm.languageList,"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.languageIdList),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.languageIdList)},model:{value:(_vm.form.languageIdList),callback:function ($$v) {_vm.$set(_vm.form, "languageIdList", $$v)},expression:"form.languageIdList"}})]}}],null,false,1127333576)})],1):_vm._e(),(
                  _vm.isFieldShow(_vm.workerFieldNameEnums.workTravelId) &&
                  !_vm.isHideTravel
                )?_c('k-form-group',{style:({
                  order: _vm.getOrderIndex(_vm.workerFieldNameEnums.workTravelId),
                }),attrs:{"id":"travelSelect","label-suffix":!_vm.getConfigVal(
                    _vm.curPageConfigAndDefaultPageConfig,
                    'travelSelect',
                    'vIf'
                  )
                    ? 'travel'
                    : '',"label":_vm.getConfigVal(
                    _vm.curPageConfigAndDefaultPageConfig,
                    'travelSelect',
                    'vIf'
                  )
                    ? 'travel'
                    : '',"label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.workTravelId)
                    ? 'require-mark'
                    : ''}},[_c('KValidationProvider',{attrs:{"rules":{
                    required: _vm.isFieldRequired(
                      _vm.workerFieldNameEnums.workTravelId
                    ),
                  },"slim":"","name":"workTravelId"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.travelList,"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.workTravelId),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.workTravelId)},model:{value:(_vm.form.workTravelId),callback:function ($$v) {_vm.$set(_vm.form, "workTravelId", $$v)},expression:"form.workTravelId"}})]}}],null,false,757848249)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.asWorkerPastThreeYear))?_c('k-form-group',{style:({
                  order: _vm.getOrderIndex(
                    _vm.workerFieldNameEnums.asWorkerPastThreeYear
                  ),
                }),attrs:{"label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.asWorkerPastThreeYear)
                    ? 'require-mark'
                    : '',"label-suffix":"pastThreeYears","id":"pastThreeYearsRadio","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
                    required: _vm.isFieldRequired(
                      _vm.workerFieldNameEnums.asWorkerPastThreeYear
                    ),
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('b-form-radio-group',{staticClass:"mt-lg-2",attrs:{"disabled":_vm.isPlainText,"options":_vm.yesOrNoOptions,"plaintext":_vm.isFieldViewOnly(
                        _vm.workerFieldNameEnums.asWorkerPastThreeYear
                      ),"tab-index":_vm.getOrderIndex(
                        _vm.workerFieldNameEnums.asWorkerPastThreeYear
                      )},model:{value:(_vm.form.asWorkerPastThreeYear),callback:function ($$v) {_vm.$set(_vm.form, "asWorkerPastThreeYear", $$v)},expression:"form.asWorkerPastThreeYear"}})]}}],null,false,2487431806)})],1):_vm._e(),(
                  !_vm.isHideStudentFields &&
                  _vm.isFieldShow(_vm.workerFieldNameEnums.jobIdList)
                )?_c('k-form-group',{style:({
                  order: _vm.getOrderIndex(_vm.workerFieldNameEnums.jobIdList),
                }),attrs:{"id":"positionPreferenceCheckbox","label-suffix":"positionPreference","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.jobIdList)
                    ? 'require-mark'
                    : ''}},[_c('KValidationProvider',{attrs:{"rules":{
                    required: _vm.isFieldRequired(_vm.workerFieldNameEnums.jobIdList),
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-checkbox-group',{staticClass:"col-form-label d-block",attrs:{"options":_vm.positionPreferenceList,"disabled":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.jobIdList),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.jobIdList),"stacked":""},model:{value:(_vm.form.jobIdList),callback:function ($$v) {_vm.$set(_vm.form, "jobIdList", $$v)},expression:"form.jobIdList"}})]}}],null,false,235571016)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.volunteer))?_c('k-form-group',{style:({
                  order: _vm.getOrderIndex(_vm.workerFieldNameEnums.volunteer),
                }),attrs:{"id":"volunteerRadio","label-suffix":"volunteer","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.volunteer)
                    ? 'require-mark'
                    : ''}},[_c('KValidationProvider',{attrs:{"rules":{
                    required: _vm.isFieldRequired(_vm.workerFieldNameEnums.volunteer),
                  },"slim":"","name":"volunteer"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-radio-group',{staticClass:"col-form-label d-block",attrs:{"disabled":_vm.isVolunteerPlainText,"options":_vm.yesOrNoOptions,"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.volunteer),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.volunteer)},model:{value:(_vm.form.volunteer),callback:function ($$v) {_vm.$set(_vm.form, "volunteer", $$v)},expression:"form.volunteer"}})]}}],null,false,337230420)})],1):_vm._e(),(
                  _vm.isFieldShow(_vm.workerFieldNameEnums.municipalityBoundaryIds)
                )?_c('k-form-group',{style:({
                  order: _vm.getOrderIndex(
                    _vm.workerFieldNameEnums.municipalityBoundaryIds
                  ),
                }),attrs:{"id":"municipalityMultiselect","label-suffix":"Municipality","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(
                    _vm.workerFieldNameEnums.municipalityBoundaryIds
                  )
                    ? 'require-mark'
                    : ''}},[_c('KValidationProvider',{attrs:{"rules":{
                    required: _vm.isFieldRequired(
                      _vm.workerFieldNameEnums.municipalityBoundaryIds
                    ),
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-multiselect',{attrs:{"options":_vm.MunicipalityBoundaryList,"plaintext":_vm.isFieldViewOnly(
                        _vm.workerFieldNameEnums.municipalityBoundaryIds
                      ),"tab-index":_vm.getOrderIndex(
                        _vm.workerFieldNameEnums.municipalityBoundaryIds
                      )},model:{value:(_vm.form.municipalityBoundaryIds),callback:function ($$v) {_vm.$set(_vm.form, "municipalityBoundaryIds", $$v)},expression:"form.municipalityBoundaryIds"}})]}}],null,false,2073704793)})],1):_vm._e(),(
                  _vm.isFieldShow(_vm.workerFieldNameEnums.workerHearAboutId) &&
                  !_vm.isHideStudentFields
                )?_c('k-form-group',{style:({
                  order: _vm.getOrderIndex(
                    _vm.workerFieldNameEnums.workerHearAboutId
                  ),
                }),attrs:{"id":"hearAboutSelect","label-suffix":!_vm.getConfigVal(
                    _vm.curPageConfigAndDefaultPageConfig,
                    'hearAboutSelect',
                    'vIf'
                  )
                    ? 'hearAbout'
                    : '',"label":_vm.getConfigVal(
                    _vm.curPageConfigAndDefaultPageConfig,
                    'hearAboutSelect',
                    'vIf'
                  )
                    ? 'hearAbout'
                    : '',"label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.workerHearAboutId)
                    ? 'require-mark'
                    : ''}},[_c('KValidationProvider',{attrs:{"rules":{
                    required: _vm.isFieldRequired(
                      _vm.workerFieldNameEnums.workerHearAboutId
                    ),
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.hearAbouts,"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.workerHearAboutId),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.workerHearAboutId)},on:{"change":_vm.changeHearAbout},model:{value:(_vm.form.workerHearAboutId),callback:function ($$v) {_vm.$set(_vm.form, "workerHearAboutId", $$v)},expression:"form.workerHearAboutId"}})]}}],null,false,299703591)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.workerHearAboutId))?_c('k-form-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHearAboutText),expression:"showHearAboutText"}],style:({
                  order: _vm.getOrderIndex(
                    _vm.workerFieldNameEnums.workerHearAboutId
                  ),
                }),attrs:{"id":"otherInput","label":_vm.getConfigVal(
                    _vm.curPageConfigAndDefaultPageConfig,
                    'otherInput',
                    'vIf'
                  )
                    ? 'other'
                    : '',"label-class":_vm.showHearAboutText ? 'require-mark' : '',"label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
                    required: _vm.showHearAboutText,
                    max: 200,
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{attrs:{"id":"workerHearAboutText","aria-label":_vm.$g('workerHearAboutText'),"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.workerHearAboutId),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.workerHearAboutId)},model:{value:(_vm.form.workerHearAboutText),callback:function ($$v) {_vm.$set(_vm.form, "workerHearAboutText", $$v)},expression:"form.workerHearAboutText"}})]}}],null,false,382239880)})],1):_vm._e(),(
                  _vm.$route.query.needWorkerTypeDocument == 1 &&
                  _vm.isFieldShow(_vm.workerFieldNameEnums.workerTypeUpload)
                )?_c('k-form-group',{style:({
                  order: _vm.getOrderIndex(_vm.workerFieldNameEnums.workerTypeUpload),
                }),attrs:{"label-suffix":_vm.$route.query.workerTypeDocumentLabel,"label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","no-locale":true,"label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.workerTypeUpload)
                    ? 'require-mark'
                    : ''}},[_c('KValidationProvider',{attrs:{"rules":{
                    required: _vm.isFieldRequired(
                      _vm.workerFieldNameEnums.workerTypeUpload
                    ),
                    acceptFileType: _vm.accept,
                    maxFileSize: _vm.size * 1024,
                  },"slim":"","name":"workerTypeDocument"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('common-upload',{attrs:{"accept":_vm.accept,"size":_vm.size,"is-multiple":true,"on-delete":_vm.onDelete,"placeholder":_vm.placeholder,"show-message":false,"is-use-internal-validate":false,"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.workerTypeUpload)},model:{value:(_vm.form.workerTypeDocument),callback:function ($$v) {_vm.$set(_vm.form, "workerTypeDocument", $$v)},expression:"form.workerTypeDocument"}})]}}],null,false,2220561465)})],1):_vm._e(),(
                  _vm.isFieldShow(_vm.workerFieldNameEnums.hadESALanguageProficiency)
                )?_c('k-form-group',{style:({
                  order: _vm.getOrderIndex(
                    _vm.workerFieldNameEnums.hadESALanguageProficiency
                  ),
                }),attrs:{"label-class":_vm.isFieldRequired(
                    _vm.workerFieldNameEnums.hadESALanguageProficiency
                  )
                    ? 'require-mark'
                    : '',"id":"hasCertificationUploadedRadio","label-suffix":"haveLanguageProficiencyCertified","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
                    required: _vm.isFieldRequired(
                      _vm.workerFieldNameEnums.hadESALanguageProficiency
                    ),
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-radio-group',{staticClass:"col-form-label d-block",attrs:{"options":_vm.yesOrNoOptions,"plaintext":_vm.isFieldViewOnly(
                        _vm.workerFieldNameEnums.hadESALanguageProficiency
                      ),"tab-index":_vm.getOrderIndex(
                        _vm.workerFieldNameEnums.hadESALanguageProficiency
                      )},on:{"change":_vm.hadESALanguageProficiencyChange},model:{value:(_vm.form.hadESALanguageProficiency),callback:function ($$v) {_vm.$set(_vm.form, "hadESALanguageProficiency", $$v)},expression:"form.hadESALanguageProficiency"}})]}}],null,false,3805215748)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.certifiedBy))?_c('k-form-group',{style:({
                  order: _vm.getOrderIndex(_vm.workerFieldNameEnums.certifiedBy),
                }),attrs:{"label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.certifiedBy)
                    ? 'require-mark'
                    : '',"id":"certifiedByText","label-suffix":"certificate","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
                    required: _vm.isFieldRequired(
                      _vm.workerFieldNameEnums.certifiedBy
                    ),
                    acceptFileType: _vm.docAccept,
                    maxFileSize: _vm.docSize * 1024,
                  },"slim":"","name":"workerTypeDocument"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('common-upload',{attrs:{"accept":_vm.docAccept,"size":_vm.docSize,"is-multiple":true,"on-delete":_vm.onDelete,"placeholder":_vm.placeholder,"show-message":false,"is-use-internal-validate":false,"plaintext":_vm.isFieldViewOnly(_vm.workerFieldNameEnums.certificate)},model:{value:(_vm.form.certificateDocument),callback:function ($$v) {_vm.$set(_vm.form, "certificateDocument", $$v)},expression:"form.certificateDocument"}})]}}],null,false,3053875226)})],1):_vm._e(),_c('k-extend-fields',{directives:[{name:"frag",rawName:"v-frag"}],ref:"workerPreferenceExtFields",model:{value:(_vm.form.workerPreferenceExtFields),callback:function ($$v) {_vm.$set(_vm.form, "workerPreferenceExtFields", $$v)},expression:"form.workerPreferenceExtFields"}}),(
                  _vm.getConfigVal(
                    _vm.curPageConfigAndDefaultPageConfig,
                    'extendFields',
                    'vIf'
                  )
                )?_c('k-extend-fields',{directives:[{name:"frag",rawName:"v-frag"}],ref:"extFields",attrs:{"id":"extendFields"},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}):_vm._e(),_c('k-extend-fields',{directives:[{name:"frag",rawName:"v-frag"}],ref:"workerTypeExtFields",model:{value:(_vm.form.workerTypeExtFields),callback:function ($$v) {_vm.$set(_vm.form, "workerTypeExtFields", $$v)},expression:"form.workerTypeExtFields"}}),(_vm.isFieldShow(_vm.workerFieldNameEnums.citizenshipDocuments))?_c('div',{staticClass:"content-title",style:({
                  order: _vm.getOrderIndex(
                    _vm.workerFieldNameEnums.citizenshipDocuments
                  ),
                })},[_vm._v(" "+_vm._s(_vm.$g( _vm.getConfigVal( _vm.curPageConfigAndDefaultPageConfig, "citizenshipDocumentUpload", "title" ) ))+" ")]):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.citizenshipDocuments))?_c('k-form-group',{style:({
                  order: _vm.getOrderIndex(
                    _vm.workerFieldNameEnums.citizenshipDocuments
                  ),
                }),attrs:{"id":"citizenshipDocumentUpload","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.citizenshipDocuments)
                    ? 'require-mark'
                    : '',"label-suffix":"document.file","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
                    required: _vm.isFieldRequired(
                      _vm.workerFieldNameEnums.citizenshipDocuments
                    ),
                    acceptFileType: _vm.accept,
                    maxFileSize: _vm.size * 1024,
                  },"slim":"","name":_vm.$g('document.file')},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('common-upload',{attrs:{"accept":_vm.accept,"size":_vm.size,"is-multiple":true,"on-delete":_vm.onDelete,"placeholder":_vm.placeholder,"show-message":false,"is-use-internal-validate":false,"plaintext":_vm.isFieldViewOnly(
                        _vm.workerFieldNameEnums.citizenshipDocuments
                      )},model:{value:(_vm.form.citizenshipDocuments),callback:function ($$v) {_vm.$set(_vm.form, "citizenshipDocuments", $$v)},expression:"form.citizenshipDocuments"}})]}}],null,false,2606998997)})],1):_vm._e()],1),_c('k-form-group',{staticClass:"wapBtnCenter",attrs:{"label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('div',{staticStyle:{"margin-top":"30px"}},[_c('k-button',{staticClass:"w-150",staticStyle:{"margin-right":"80px"},attrs:{"variant":"utility"},on:{"click":function($event){return _vm.backAction(0)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.backAction(0)}}},[_vm._v(" back ")]),_c('k-button',{staticClass:"w-150",attrs:{"type":"submit","variant":"primary","disabled":_vm.isDisable},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.save.apply(null, arguments)}}},[_vm._v(" next ")])],1)])],1)])],1)],1):(_vm.curIndex === 1)?_c('NotificationPreferences',{attrs:{"form":_vm.form},on:{"next-action":_vm.nextAction,"back-action":_vm.backAction}}):(
        _vm.getConfigVal(
          _vm.curPageConfigAndDefaultPageConfig,
          'termsAndConditions',
          'vIf'
        ) && _vm.curIndex === 2
      )?_c('TermsAndConditions',{attrs:{"id":"termsAndConditions"},on:{"next-action":_vm.nextAction,"back-action":_vm.backAction},model:{value:(_vm.termsChecked),callback:function ($$v) {_vm.termsChecked=$$v},expression:"termsChecked"}}):(
        _vm.getConfigVal(_vm.curPageConfigAndDefaultPageConfig, 'preview', 'vIf') &&
        _vm.getConfigVal(
          _vm.curPageConfigAndDefaultPageConfig,
          'termsAndConditions',
          'vIf'
        )
          ? _vm.curIndex === 3
          : _vm.curIndex === 2
      )?_c('Preview',{attrs:{"id":"preview","form":_vm.form,"political-party-required":_vm.politicalPartyRequired,"addressformat":_vm.addressformat,"mailingaddressformat":_vm.mailingaddressformat,"residential-address-form":_vm.residentialAddressForm,"postal-address-form":_vm.postalAddressForm,"municipality-boundary-list":_vm.MunicipalityBoundaryList,"hear-abouts":_vm.hearAbouts,"show-hear-about-text":_vm.showHearAboutText,"need-address-description":_vm.needAddressDescription,"is-hide-student-fields":_vm.isHideStudentFields,"prop-dob-options":_vm.dobOptions,"tag-list":_vm.tagList,"is-job-code-require":_vm.isFieldRequired(_vm.workerFieldNameEnums.jobCode),"ssn-digits":_vm.ssnDigits},on:{"next-action":_vm.nextAction,"back-action":_vm.backAction}}):_vm._e()],_c('k-popwindow',{staticClass:"content-form",attrs:{"id":"popCropper","title":_vm.$g('popwindow.photo'),"before-close":_vm.onBeforeClose,"check-selected":false},on:{"ok":_vm.onPopCropper}},[_c('PhotoTailor',{ref:"PhotoTailor",attrs:{"request-api":_vm.uploadPhotoRequest,"getimgurldata":_vm.getImgUrlData}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }