<template>
  <div>
    <!-- <div class="applicationTitle mb-0">{{ clientName }}</div> -->
    <!-- <div class="applicationSubTitle">{{ subTitle }}</div> -->
    <!-- <div class="font-weight-bold featuredFont">{{ $g(title) }}</div> -->
    <div class="applicationDescription" v-html="desc"></div>
  </div>
</template>

<script>
import { getClientName, getLoadingPageTitle } from "@/api/application";

export default {
  props: {
    title: {
      type: String,
      default() {
        return null;
      },
    },
    subTitle: {
      type: String,
      default() {
        return null;
      },
    },
    id: {
      type: String,
      default() {
        return null;
      },
    },
    pageName: {
      type: String,
      default() {
        return null;
      },
    },
  },

  data() {
    return {
      clientName: "",
      topData: {
        accessing: 0,
        onDescription: "",
        offDescription: "",
      },
      desc: "",
    };
  },
  watch: {},
  mounted() {
    getClientName(this.id).then((data) => {
      this.clientName = data.data.clientName;
    });
    getLoadingPageTitle().then((data) => {
      this.topData = data.data;
    });
  },
  methods: {
    setDesc(content) {
      this.desc = content?.replace("$WorkerType$",this.subTitle);
    },
  },
};
</script>
