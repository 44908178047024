<template>
  <div class="applicationPage">
    <HeadInfo :hide-right-items="true" />
    <div class="applicationMain">
      <div class="applicationHead">
        <!-- <div>
          <img
            src="@/assets/img/regular-img.png"
            width="280"
            alt="regular icon"
          />
        </div> -->
        <div>
          <slot name="topModule" />
        </div>
      </div>
      <div class="d-flex flex-wrap">
        <div class="applicationSidebar wapHide">
          <div
            id="timelineListDiv"
            v-for="(item, index) in timelineList"
            :key="index"
            class="iconStep"
            :class="index < selectIndex ? 'activeStep' : ''"
          >
            <b-icon :icon="item.icon" @click="skip(index)" />
            <div v-show="index != timelineList.length - 1" class="long-line" />
          </div>
        </div>
        <div class="contentParty applicationContent noNavTabCard">
          <slot />
        </div>
      </div>
    </div>
    <FooterInfo />
  </div>
</template>

<script>
import HeadInfo from "@/layout/headInfo";
import FooterInfo from "@/layout/footerInfo";
import {
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
} from "fe-infrastractures";

export default {
  props: {
    curIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  components: {
    HeadInfo,
    FooterInfo,
  },
  data() {
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );
    return {
      timelineList: getConfigVal(
        curPageConfigAndDefaultPageConfig,
        "timelineListDiv",
        "list"
      ),
      stateIndex: 0,
    };
  },

  computed: {
    selectIndex() {
      if (this.curIndex > this.stateIndex) {
        this.stateIndex = JSON.parse(JSON.stringify(this.curIndex));
      }
      return this.stateIndex;
    },
  },
  mounted() {},
  methods: {
    skip(index) {
      if (index < this.stateIndex) {
        this.$parent.curIndex = index;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.iconStep {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  .b-icon.bi {
    border: 1px solid #dddddd;
    width: 42px;
    height: 42px;
    line-height: 42px;
    border-radius: 50%;
    padding: 10px;
    cursor: auto;
    color: initial;
    background: #ffffff;
    color: #333333;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
    cursor: auto;
  }

  &.activeStep {
    .b-icon.bi {
      color: #fff;
      background: #1e8c4c;
      cursor: pointer;
    }

    .long-line {
      background: #1e8c4c;
    }
  }

  .long-line {
    width: 1px;
    height: 50px;
    background: #a7a7a7;
  }
}
</style>
