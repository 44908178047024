<template>
  <KValidationObserver
    ref="observer"
    class="cardBody border-0"
    style="display: flex; flex-direction: column"
  >
    <k-form-group
      :label-class="
        isFieldRequired(workerFieldNameEnums.languageId) ? 'require-mark' : ''
      "
      v-if="isFieldShow(workerFieldNameEnums.languageId)"
      :style="{
        order: getOrderIndex(workerFieldNameEnums.languageId),
      }"
      id="languageSelect"
      label-suffix="language"
      label="language"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
      label-for="noneBind"
    >
      <KValidationProvider
        :rules="{
          required: isFieldRequired(workerFieldNameEnums.languageId),
        }"
        v-slot="{ errors }"
        slim
        name="language"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-select
          v-model="applicationFromData.languageId"
          :options="systemLanguageList"
          :plaintext="!edit || isFieldViewOnly(workerFieldNameEnums.languageId)"
          :tab-index="getOrderIndex(workerFieldNameEnums.languageId)"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      id="preferredContactMethodSelect"
      :label-class="
        isFieldRequired(workerFieldNameEnums.preferredContactMethod)
          ? 'require-mark'
          : ''
      "
      v-if="isFieldShow(workerFieldNameEnums.preferredContactMethod)"
      :style="{
        order: getOrderIndex(workerFieldNameEnums.preferredContactMethod),
      }"
      label-suffix="preferredContactMethod"
      label-for="noneBind"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
    >
      <KValidationProvider
        :rules="{
          required: isFieldRequired(
            workerFieldNameEnums.preferredContactMethod
          ),
        }"
        v-slot="{ errors }"
        slim
        name="preferredContactMethod"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-select
          v-model="applicationFromData.preferredContactMethod"
          :options="enumCommunicationSendTypeList"
          :plaintext="
            !edit ||
            isFieldViewOnly(workerFieldNameEnums.preferredContactMethod)
          "
          :tab-index="
            getOrderIndex(workerFieldNameEnums.preferredContactMethod) ||
            isFieldViewOnly(workerFieldNameEnums.preferredContactMethod)
          "
          :aria-label="$g('preferredContactMethod')"
          @change="preferredContactMethodChange"
        />
      </KValidationProvider>
    </k-form-group>

    <k-form-group
      :label-class="
        isFieldRequired(workerFieldNameEnums.useEmail) ? 'require-mark' : ''
      "
      v-if="isFieldShow(workerFieldNameEnums.useEmail)"
      :style="{
        order: getOrderIndex(workerFieldNameEnums.useEmail),
      }"
      id="emailRadio"
      label-suffix="email"
      label-for="noneBind"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
    >
      <KValidationProvider
        :rules="{
          required: isFieldRequired(workerFieldNameEnums.useEmail),
        }"
        name="emailPreference"
        v-slot="{ errors }"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <b-form-radio-group
          v-model="applicationFromData.useEmail"
          class="col-form-label wapHide767"
          :plaintext="!edit || isFieldViewOnly(workerFieldNameEnums.useEmail)"
          :disabled="!edit || isFieldViewOnly(workerFieldNameEnums.useEmail)"
          @change="emailPreferenceChange"
          :tab-index="getOrderIndex(workerFieldNameEnums.useEmail)"
        >
          <b-form-radio :value="2">
            {{ $g("on") }}
          </b-form-radio>
          <b-form-radio :value="1">
            {{ $g("off") }}
          </b-form-radio>
        </b-form-radio-group>
        <b-form-checkbox
          v-model="applicationFromData.useEmail"
          :plaintext="!edit || isFieldViewOnly(workerFieldNameEnums.useEmail)"
          :disabled="!edit || isFieldViewOnly(workerFieldNameEnums.useEmail)"
          :tab-index="getOrderIndex(workerFieldNameEnums.useEmail)"
          switch
          size="lg"
          :value="2"
          :unchecked-value="1"
          @change="emailPreferenceChange"
          class="wapShow767"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      id="emailAddressInput"
      v-if="isFieldShow(workerFieldNameEnums.email)"
      :style="{
        order: getOrderIndex(workerFieldNameEnums.email),
      }"
      label-suffix="emailAddress"
      label-for="noneBind"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
      :label-class="
        isFieldRequired(workerFieldNameEnums.email) ||
        emailPreferenceOn ||
        applicationFromData.preferredContactMethod == 1
          ? 'require-mark'
          : ''
      "
    >
      <KValidationProvider
        :rules="{
          max: 200,
          email: true,
          required:
            isFieldRequired(workerFieldNameEnums.email) ||
            emailPreferenceOn ||
            applicationFromData.preferredContactMethod == 1,
          remote: checkEmail,
        }"
        name="email"
        v-slot="{ errors }"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-input
          id="emailAddress"
          v-model="applicationFromData.email"
          :plaintext="!edit || isFieldViewOnly(workerFieldNameEnums.email)"
          :aria-label="$g('emailAddress')"
          :tab-index="getOrderIndex(workerFieldNameEnums.email)"
        />
      </KValidationProvider>
    </k-form-group>

    <k-form-group
      id="messagesRadio"
      v-if="isFieldShow(workerFieldNameEnums.useSMS)"
      :style="{
        order: getOrderIndex(workerFieldNameEnums.useSMS),
      }"
      :label-class="
        isFieldRequired(workerFieldNameEnums.useSMS) ? 'require-mark' : ''
      "
      label-suffix="messages"
      label-for="noneBind"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
    >
      <KValidationProvider
        :rules="{
          required: isFieldRequired(workerFieldNameEnums.useSMS),
        }"
        name="smsPreference"
        v-slot="{ errors }"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <b-form-radio-group
          v-model="applicationFromData.useSms"
          class="col-form-label wapHide767"
          :plaintext="!edit || isFieldViewOnly(workerFieldNameEnums.useSMS)"
          :tab-index="getOrderIndex(workerFieldNameEnums.useSMS)"
          :disabled="!edit || isFieldViewOnly(workerFieldNameEnums.useSMS)"
          @change="smsPreferenceChange"
        >
          <b-form-radio :value="2">
            {{ $g("on") }}
          </b-form-radio>
          <b-form-radio :value="1">
            {{ $g("off") }}
          </b-form-radio>
        </b-form-radio-group>
        <b-form-checkbox
          v-model="applicationFromData.useSms"
          :plaintext="!edit || isFieldViewOnly(workerFieldNameEnums.useSMS)"
          :tab-index="getOrderIndex(workerFieldNameEnums.useSMS)"
          :disabled="!edit || isFieldViewOnly(workerFieldNameEnums.useSMS)"
          switch
          size="lg"
          :value="2"
          :unchecked-value="1"
          @change="smsPreferenceChange"
          class="wapShow767"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      id="phoneNumberInput"
      :label-suffix="
        getConfigVal(
          curPageConfigAndDefaultPageConfig,
          'phoneNumberInput',
          'labelSuffix'
        )
      "
      label-for="noneBind"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
      v-if="isFieldShow(workerFieldNameEnums.sms)"
      :style="{
        order: getOrderIndex(workerFieldNameEnums.sms),
      }"
      :label-class="
        smsPreferenceOn ||
        isFieldRequired(workerFieldNameEnums.sms) ||
        applicationFromData.preferredContactMethod == 4
          ? 'require-mark'
          : ''
      "
    >
      <KValidationProvider
        :rules="{
          phone: true,
          required:
            smsPreferenceOn ||
            isFieldRequired(workerFieldNameEnums.sms) ||
            applicationFromData.preferredContactMethod == 4,
        }"
        slim
        name="phoneNumber"
        v-slot="{ errors }"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-phone-input
          id="phoneNumber"
          v-model="applicationFromData.sms"
          :plaintext="!edit || isFieldViewOnly(workerFieldNameEnums.sms)"
          :tab-index="getOrderIndex(workerFieldNameEnums.sms)"
          :aria-label="$g('phoneNumber')"
        />
      </KValidationProvider>
    </k-form-group>

    <k-form-group
      id="phoneCallRadio"
      v-if="isFieldShow(workerFieldNameEnums.usePhone)"
      :style="{
        order: getOrderIndex(workerFieldNameEnums.usePhone),
      }"
      :label-class="
        isFieldRequired(workerFieldNameEnums.usePhone) ? 'require-mark' : ''
      "
      label-suffix="phoneCall"
      label-for="noneBind"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
    >
      <KValidationProvider
        :rules="{
          required: isFieldRequired(workerFieldNameEnums.usePhone),
        }"
        name="phonePreference"
        v-slot="{ errors }"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <b-form-radio-group
          v-model="applicationFromData.usePhone"
          class="col-form-label wapHide767"
          :plaintext="!edit || isFieldViewOnly(workerFieldNameEnums.usePhone)"
          :disabled="!edit || isFieldViewOnly(workerFieldNameEnums.usePhone)"
          @change="phonePreferenceChange"
          :tab-index="getOrderIndex(workerFieldNameEnums.usePhone)"
        >
          <b-form-radio :value="2">
            {{ $g("on") }}
          </b-form-radio>
          <b-form-radio :value="1">
            {{ $g("off") }}
          </b-form-radio>
        </b-form-radio-group>
        <b-form-checkbox
          v-model="applicationFromData.usePhone"
          :plaintext="!edit || isFieldViewOnly(workerFieldNameEnums.usePhone)"
          :disabled="!edit || isFieldViewOnly(workerFieldNameEnums.usePhone)"
          :tab-index="getOrderIndex(workerFieldNameEnums.usePhone)"
          switch
          size="lg"
          :value="2"
          :unchecked-value="1"
          @change="phonePreferenceChange"
          class="wapShow767"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      id="phoneNumber2Input"
      :label-suffix="
        getConfigVal(
          curPageConfigAndDefaultPageConfig,
          'phoneNumber2Input',
          'labelSuffix'
        )
      "
      v-if="isFieldShow(workerFieldNameEnums.phone)"
      :style="{
        order: getOrderIndex(workerFieldNameEnums.phone),
      }"
      label-for="noneBind"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
      :label-class="
        phonePreferenceOn ||
        isFieldRequired(workerFieldNameEnums.phone) ||
        applicationFromData.preferredContactMethod == 2
          ? 'require-mark'
          : ''
      "
    >
      <KValidationProvider
        :rules="{
          phone: true,
          required:
            phonePreferenceOn ||
            isFieldRequired(workerFieldNameEnums.phone) ||
            applicationFromData.preferredContactMethod == 2,
        }"
        slim
        name="phoneNumber2"
        v-slot="{ errors }"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-phone-input
          id="phoneNumber2"
          v-model="applicationFromData.phone"
          :plaintext="!edit || isFieldViewOnly(workerFieldNameEnums.phone)"
          :aria-label="$g('phoneNumber')"
          :tab-index="getOrderIndex(workerFieldNameEnums.phone)"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      v-if="isFieldShow(workerFieldNameEnums.workPhone)"
      :style="{
        order: getOrderIndex(workerFieldNameEnums.workPhone),
      }"
      :label-class="
        phonePreferenceOn && isFieldRequired(workerFieldNameEnums.workPhone)
          ? 'require-mark'
          : ''
      "
      label-suffix="workPhoneNumber"
      id="workPhoneInput"
      label-for="noneBind"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
    >
      <KValidationProvider
        :rules="{
          phone: true,
          required:
            phonePreferenceOn &&
            isFieldRequired(workerFieldNameEnums.workPhone),
          max: 200,
        }"
        slim
        name="workPhone"
        v-slot="{ errors }"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-phone-input
          id="workPhone"
          v-model="applicationFromData.workPhone"
          :plaintext="!edit || isFieldViewOnly(workerFieldNameEnums.workPhone)"
          :tab-index="getOrderIndex(workerFieldNameEnums.workPhone)"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      id="doNotDisturbHoursRadio"
      v-if="isFieldShow(workerFieldNameEnums.isUseNoDisturbHours)"
      :style="{
        order: getOrderIndex(workerFieldNameEnums.isUseNoDisturbHours),
      }"
      :label-class="
        isFieldRequired(workerFieldNameEnums.isUseNoDisturbHours)
          ? 'require-mark'
          : ''
      "
      label-suffix="doNotDisturbHours"
      label-for="noneBind"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
    >
      <KValidationProvider
        :rules="{
          required: isFieldRequired(workerFieldNameEnums.isUseNoDisturbHours),
        }"
        name="notDisturb"
        v-slot="{ errors }"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <b-form-radio-group
          v-model="applicationFromData.notDisturb"
          class="col-form-label wapHide767"
          :plaintext="
            !edit || isFieldViewOnly(workerFieldNameEnums.isUseNoDisturbHours)
          "
          :disabled="
            !edit || isFieldViewOnly(workerFieldNameEnums.isUseNoDisturbHours)
          "
          @change="notDisturbChange"
          :tab-index="getOrderIndex(workerFieldNameEnums.isUseNoDisturbHours)"
        >
          <b-form-radio :value="2">
            {{ $g("on") }}
          </b-form-radio>
          <b-form-radio :value="1">
            {{ $g("off") }}
          </b-form-radio>
        </b-form-radio-group>
        <b-form-checkbox
          v-model="applicationFromData.notDisturb"
          :plaintext="
            !edit || isFieldViewOnly(workerFieldNameEnums.isUseNoDisturbHours)
          "
          :disabled="
            !edit || isFieldViewOnly(workerFieldNameEnums.isUseNoDisturbHours)
          "
          :tab-index="getOrderIndex(workerFieldNameEnums.isUseNoDisturbHours)"
          switch
          size="lg"
          :value="2"
          :unchecked-value="1"
          @change="notDisturbChange"
          class="wapShow767"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      id="notDisturbFromTimepicker"
      label-suffix="hours"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
      :label-class="
        notDisturbOn && isFieldRequired(workerFieldNameEnums.noDisturbHours)
          ? 'require-mark'
          : ''
      "
      label-for="noneBind"
      v-if="notDisturbOn && isFieldShow(workerFieldNameEnums.noDisturbHours)"
      :style="{
        order: getOrderIndex(workerFieldNameEnums.noDisturbHours),
      }"
    >
      <KValidationProvider
        :rules="{
          required:
            (notDisturbOn &&
              isFieldRequired(workerFieldNameEnums.noDisturbHours)) ||
            applicationFromData.notDisturbTo > 0,
          validateTimeFormat: true,
        }"
        slim
        v-slot="{ errors }"
        class="inline-time"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-timepicker
          v-model="applicationFromData.notDisturbFrom"
          class="datepicker-time"
          :tab-index="getOrderIndex(workerFieldNameEnums.noDisturbHours)"
          :plaintext="
            !edit || isFieldViewOnly(workerFieldNameEnums.noDisturbHours)
          "
          :class="{ minWidth: !edit }"
        />
      </KValidationProvider>
      <span class="timePart">-</span>
      <KValidationProvider
        :rules="{
          required:
            (notDisturbOn &&
              isFieldRequired(workerFieldNameEnums.noDisturbHours)) ||
            applicationFromData.notDisturbFrom > 0,
          validateTimeFormat: true,
        }"
        slim
        v-slot="{ errors }"
        class="inline-time"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-timepicker
          v-model="applicationFromData.notDisturbTo"
          class="datepicker-time"
          :tab-index="getOrderIndex(workerFieldNameEnums.noDisturbHours)"
          :plaintext="
            !edit || isFieldViewOnly(workerFieldNameEnums.noDisturbHours)
          "
          :class="{ minWidth: !edit }"
        />
      </KValidationProvider>
    </k-form-group>
    <k-form-group
      id="additionalPhoneNumberInput"
      v-if="isFieldShow(workerFieldNameEnums.additionalPhoneNumber)"
      :style="{
        order: getOrderIndex(workerFieldNameEnums.additionalPhoneNumber),
      }"
      :label-class="
        isFieldRequired(workerFieldNameEnums.additionalPhoneNumber)
          ? 'require-mark'
          : ''
      "
      label-suffix="additionalPhoneNumber"
      label-for="noneBind"
      label-align-md="right"
      label-cols-md="3"
      content-cols-lg="6"
      content-cols-md="7"
    >
      <KValidationProvider
        :rules="{
          required: isFieldRequired(workerFieldNameEnums.additionalPhoneNumber),
          phone: true,
        }"
        slim
        name="additionalPhoneNumber"
        v-slot="{ errors }"
      >
        <k-alert :show="errors.length > 0" variant="warning">
          {{ errors[0] }}
        </k-alert>
        <k-form-phone-input
          id="additionalPhoneNumber"
          v-model="applicationFromData.additionalPhoneNumber"
          :plaintext="
            !edit || isFieldViewOnly(workerFieldNameEnums.additionalPhoneNumber)
          "
          :tab-index="getOrderIndex(workerFieldNameEnums.additionalPhoneNumber)"
          :aria-label="$g('additionalPhoneNumber')"
        />
      </KValidationProvider>
    </k-form-group>
  </KValidationObserver>
</template>

<script>
import { getPersonLanguageList } from "@/api/home";
import { getPreferredMethod, checkEmailAddressUsed } from "@/api/application";
import {
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
} from "fe-infrastractures";
import {
  getFieldDisplayName,
  getFieldIsHidden,
  getFieldIsRequired,
  getFieldIsViewOnly,
  getFieldOrderIndex,
  workerFieldNameEnums,
  workerModuleType,
} from "@/utils/enums/workerTypeField";

export default {
  props: {
    edit: {
      type: Boolean,
      default() {
        return false;
      },
    },
    application: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  model: { prop: "application", event: "input" },
  components: {},
  data() {
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );
    return {
      curPageConfigAndDefaultPageConfig,
      applicationFromData: {
        languageId: "",
        preferredContactMethod: "",
        useEmail: 2,
        email: "",
        useSms: 2,
        sms: "",
        usePhone: 2,
        phone: "",
        notDisturb: 1,
        notDisturbFrom: "",
        notDisturbTo: "",
        additionalPhoneNumber: "",
      },
      systemLanguageList: [],
      enumCommunicationSendTypeList: [],
      curSystemLanguage: "",
      currEnumCommunicationSendType: "",
      electionId: this.electionId,
      emailPreferenceOn: false,
      smsPreferenceOn: false,
      phonePreferenceOn: false,
      notDisturbOn: false,
      workerFieldNameEnums: workerFieldNameEnums,
      moduleType: workerModuleType.workerPortalApplication,
    };
  },
  mounted() {
    this.bindData();
    this.bindSystemType();
    getPreferredMethod().then((resp) => {
      resp.data.forEach((item) => {
        if (item.value == 0) {
          item.value = null;
        }
      });
      this.enumCommunicationSendTypeList = resp.data;
    });
    if (this.isFieldShow(workerFieldNameEnums.preferredContactMethod)) {
      this.preferredContactMethodChange(
        this.applicationFromData.preferredContactMethod
      );
    }
  },
  methods: {
    getConfigVal,
    getOrderIndex(fieldName) {
      return getFieldOrderIndex(
        fieldName,
        this.application.workerProfileSettings
      );
    },
    isFieldShow(fieldName) {
      return !getFieldIsHidden(
        fieldName,
        this.application.workerProfileSettings,
        this.moduleType
      );
    },
    getDisplayName(fieldName) {
      return getFieldDisplayName(
        fieldName,
        this.application.workerProfileSettings
      );
    },
    isFieldRequired(fieldName) {
      return getFieldIsRequired(
        fieldName,
        this.application.workerProfileSettings,
        this.moduleType
      );
    },
    isFieldViewOnly(fieldName) {
      return getFieldIsViewOnly(
        fieldName,
        this.application.workerProfileSettings,
        this.moduleType
      );
    },
    async checkEmail() {
      if (!this.edit) {
        return;
      }
      const data = {
        id: this.$route.query.id ?? "",
        name: this.applicationFromData.email,
      };
      let result = {
        info: null,
        status: 1,
      };
      await checkEmailAddressUsed(data).then(function (rep) {
        result = rep;
      });
      return result;
    },
    bindSystemType() {
      this.bindLanguageList();
    },

    bindLanguageList() {
      getPersonLanguageList().then((resp) => {
        if (resp.data && resp.data.length > 0) {
          this.systemLanguageList.push({ text: "", value: "" });
          resp.data.forEach((element) => {
            this.systemLanguageList.push(element);
          });
          const l = this.systemLanguageList.find(
            (it) => it.value == this.application.languageId
          );
          if (l != null) {
            this.applicationFromData.languageId = this.application.languageId;
          }
          if (
            !this.isFieldShow(workerFieldNameEnums.languageId) &&
            (!this.applicationFromData.languageId ||
              this.applicationFromData.languageId.length == 0)
          ) {
            this.systemLanguageList.forEach((item) => {
              if (item.isDefault) {
                this.applicationFromData.languageId = item.value;
              }
            });
          }
        }
      });
    },

    onTimeChange() {
      let me = this;
      setTimeout(function () {
        me.$refs.observer.validate();
      }, 1);
    },
    bindData() {
      if (this.application.preferredContactMethod != 0) {
        this.applicationFromData.preferredContactMethod =
          this.application.preferredContactMethod ?? "";
      }
      let emailRadioDefeatValue =
        this.getConfigVal(
          this.curPageConfigAndDefaultPageConfig,
          "emailRadio",
          "defeatValue"
        ) ?? 1;
      this.$set(
        this.applicationFromData,
        "useEmail",
        this.isFieldShow(workerFieldNameEnums.useEmail)
          ? this.application.useEmail
          : emailRadioDefeatValue
      );
      this.$set(this.applicationFromData, "email", this.application.email);
      let messagesRadioDefeatValue =
        this.getConfigVal(
          this.curPageConfigAndDefaultPageConfig,
          "messagesRadio",
          "defeatValue"
        ) ?? 1;
      this.$set(
        this.applicationFromData,
        "useSms",
        this.isFieldShow(workerFieldNameEnums.useSMS)
          ? this.application.useSms
          : messagesRadioDefeatValue
      );
      this.$set(this.applicationFromData, "sms", this.application.sms);

      let phoneCallRadioDefeatValue =
        this.getConfigVal(
          this.curPageConfigAndDefaultPageConfig,
          "phoneCallRadio",
          "defeatValue"
        ) ?? 1;
      this.$set(
        this.applicationFromData,
        "usePhone",
        this.isFieldShow(workerFieldNameEnums.usePhone)
          ? this.application.usePhone
          : phoneCallRadioDefeatValue
      );
      this.$set(this.applicationFromData, "phone", this.application.phone);

      this.$set(
        this.applicationFromData,
        "notDisturb",
        this.isFieldShow(workerFieldNameEnums.isUseNoDisturbHours)
          ? this.application.notDisturb
          : 1
      );
      this.$set(
        this.applicationFromData,
        "workPhone",
        this.application.workPhone
      );
      this.$set(
        this.applicationFromData,
        "notDisturbFrom",
        this.application.notDisturbFrom == 0 ||
          this.application.notDisturbFrom == -2208988800000
          ? null
          : this.application.notDisturbFrom
      );
      this.$set(
        this.applicationFromData,
        "notDisturbTo",
        this.application.notDisturbTo == 0 ||
          this.application.notDisturbTo == -2208988800000
          ? null
          : this.application.notDisturbTo
      );
      this.$set(
        this.applicationFromData,
        "additionalPhoneNumber",
        this.application.additionalPhoneNumber
      );
      if (
        this.getConfigVal(
          this.curPageConfigAndDefaultPageConfig,
          "ContactMethodChangeEvent",
          "vIf"
        )
      ) {
        if (this.applicationFromData.preferredContactMethod) {
          this.preferredContactMethodChange(
            this.applicationFromData.preferredContactMethod
          );
        } else {
          this.emailPreferenceOn = true;
          this.smsPreferenceOn = true;
          this.phonePreferenceOn = true;
        }
      } else {
        this.emailPreferenceOn = this.applicationFromData.useEmail == 2;
        this.smsPreferenceOn = this.applicationFromData.useSms == 2;
        this.phonePreferenceOn = this.applicationFromData.usePhone == 2;
      }
      this.notDisturbChange(this.applicationFromData.notDisturb);
    },
    preferredContactMethodChange(val) {
      if (
        this.getConfigVal(
          this.curPageConfigAndDefaultPageConfig,
          "ContactMethodChangeEvent",
          "vIf"
        )
      ) {
        switch (val) {
          case 1:
            this.emailPreferenceOn = true;
            this.smsPreferenceOn = false;
            this.phonePreferenceOn = false;
            break;
          case 2:
            this.emailPreferenceOn = false;
            this.smsPreferenceOn = false;
            this.phonePreferenceOn = true;
            break;
          case 4:
            this.emailPreferenceOn = false;
            this.smsPreferenceOn = true;
            this.phonePreferenceOn = false;
            break;
          default:
            break;
        }
      }
    },
    emailPreferenceChange(val) {
      this.emailPreferenceOn = val == 2;
    },
    smsPreferenceChange(val) {
      this.smsPreferenceOn = val == 2;
    },
    phonePreferenceChange(val) {
      this.phonePreferenceOn = val == 2;
    },
    notDisturbChange(val) {
      this.notDisturbOn = val == 2;
      if (!this.notDisturbOn) {
        this.applicationFromData.noDisturbHoursStart = "";
        this.applicationFromData.noDisturbHoursEnd = "";
      }
    },
  },
  watch: {
    applicationFromData: {
      handler: function (np) {
        if (!np.notDisturb) {
          np.notDisturbFrom = "";
          np.notDisturbTo = "";
        }
        const data = {};
        data.languageId =
          np.languageId.length > 0
            ? np.languageId
            : this.application.languageId;
        data.preferredContactMethod = np.preferredContactMethod;
        data.useEmail = np.useEmail;
        data.email = np.email;
        data.useSms = np.useSms;
        data.sms = np.sms;
        data.workPhone = np.workPhone;
        data.usePhone = np.usePhone;
        data.phone = np.phone;
        data.notDisturb = np.notDisturb;
        data.notDisturbFrom = np.notDisturbFrom;
        data.notDisturbTo = np.notDisturbTo;
        data.additionalPhoneNumber = np.additionalPhoneNumber;

        this.$emit("input", Object.assign(this.application, data));
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .custom-switch {
  position: absolute;
  right: 0;
  top: -24px;
}
</style>
