var render = function render(){var _vm=this,_c=_vm._self._c;return _c('KValidationObserver',{ref:"observer",staticClass:"cardBody border-0",staticStyle:{"display":"flex","flex-direction":"column"}},[(_vm.isFieldShow(_vm.workerFieldNameEnums.languageId))?_c('k-form-group',{style:({
      order: _vm.getOrderIndex(_vm.workerFieldNameEnums.languageId),
    }),attrs:{"label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.languageId) ? 'require-mark' : '',"id":"languageSelect","label-suffix":"language","label":"language","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-for":"noneBind"}},[_c('KValidationProvider',{attrs:{"rules":{
        required: _vm.isFieldRequired(_vm.workerFieldNameEnums.languageId),
      },"slim":"","name":"language"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.systemLanguageList,"plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.languageId),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.languageId)},model:{value:(_vm.applicationFromData.languageId),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "languageId", $$v)},expression:"applicationFromData.languageId"}})]}}],null,false,2720440202)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.preferredContactMethod))?_c('k-form-group',{style:({
      order: _vm.getOrderIndex(_vm.workerFieldNameEnums.preferredContactMethod),
    }),attrs:{"id":"preferredContactMethodSelect","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.preferredContactMethod)
        ? 'require-mark'
        : '',"label-suffix":"preferredContactMethod","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
        required: _vm.isFieldRequired(
          _vm.workerFieldNameEnums.preferredContactMethod
        ),
      },"slim":"","name":"preferredContactMethod"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-select',{attrs:{"options":_vm.enumCommunicationSendTypeList,"plaintext":!_vm.edit ||
          _vm.isFieldViewOnly(_vm.workerFieldNameEnums.preferredContactMethod),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.preferredContactMethod) ||
          _vm.isFieldViewOnly(_vm.workerFieldNameEnums.preferredContactMethod),"aria-label":_vm.$g('preferredContactMethod')},on:{"change":_vm.preferredContactMethodChange},model:{value:(_vm.applicationFromData.preferredContactMethod),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "preferredContactMethod", $$v)},expression:"applicationFromData.preferredContactMethod"}})]}}],null,false,1382840775)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.useEmail))?_c('k-form-group',{style:({
      order: _vm.getOrderIndex(_vm.workerFieldNameEnums.useEmail),
    }),attrs:{"label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.useEmail) ? 'require-mark' : '',"id":"emailRadio","label-suffix":"email","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
        required: _vm.isFieldRequired(_vm.workerFieldNameEnums.useEmail),
      },"name":"emailPreference"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('b-form-radio-group',{staticClass:"col-form-label wapHide767",attrs:{"plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.useEmail),"disabled":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.useEmail),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.useEmail)},on:{"change":_vm.emailPreferenceChange},model:{value:(_vm.applicationFromData.useEmail),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "useEmail", $$v)},expression:"applicationFromData.useEmail"}},[_c('b-form-radio',{attrs:{"value":2}},[_vm._v(" "+_vm._s(_vm.$g("on"))+" ")]),_c('b-form-radio',{attrs:{"value":1}},[_vm._v(" "+_vm._s(_vm.$g("off"))+" ")])],1),_c('b-form-checkbox',{staticClass:"wapShow767",attrs:{"plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.useEmail),"disabled":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.useEmail),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.useEmail),"switch":"","size":"lg","value":2,"unchecked-value":1},on:{"change":_vm.emailPreferenceChange},model:{value:(_vm.applicationFromData.useEmail),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "useEmail", $$v)},expression:"applicationFromData.useEmail"}})]}}],null,false,577934832)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.email))?_c('k-form-group',{style:({
      order: _vm.getOrderIndex(_vm.workerFieldNameEnums.email),
    }),attrs:{"id":"emailAddressInput","label-suffix":"emailAddress","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.email) ||
      _vm.emailPreferenceOn ||
      _vm.applicationFromData.preferredContactMethod == 1
        ? 'require-mark'
        : ''}},[_c('KValidationProvider',{attrs:{"rules":{
        max: 200,
        email: true,
        required:
          _vm.isFieldRequired(_vm.workerFieldNameEnums.email) ||
          _vm.emailPreferenceOn ||
          _vm.applicationFromData.preferredContactMethod == 1,
        remote: _vm.checkEmail,
      },"name":"email"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-input',{attrs:{"id":"emailAddress","plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.email),"aria-label":_vm.$g('emailAddress'),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.email)},model:{value:(_vm.applicationFromData.email),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "email", $$v)},expression:"applicationFromData.email"}})]}}],null,false,2001409387)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.useSMS))?_c('k-form-group',{style:({
      order: _vm.getOrderIndex(_vm.workerFieldNameEnums.useSMS),
    }),attrs:{"id":"messagesRadio","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.useSMS) ? 'require-mark' : '',"label-suffix":"messages","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
        required: _vm.isFieldRequired(_vm.workerFieldNameEnums.useSMS),
      },"name":"smsPreference"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('b-form-radio-group',{staticClass:"col-form-label wapHide767",attrs:{"plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.useSMS),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.useSMS),"disabled":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.useSMS)},on:{"change":_vm.smsPreferenceChange},model:{value:(_vm.applicationFromData.useSms),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "useSms", $$v)},expression:"applicationFromData.useSms"}},[_c('b-form-radio',{attrs:{"value":2}},[_vm._v(" "+_vm._s(_vm.$g("on"))+" ")]),_c('b-form-radio',{attrs:{"value":1}},[_vm._v(" "+_vm._s(_vm.$g("off"))+" ")])],1),_c('b-form-checkbox',{staticClass:"wapShow767",attrs:{"plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.useSMS),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.useSMS),"disabled":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.useSMS),"switch":"","size":"lg","value":2,"unchecked-value":1},on:{"change":_vm.smsPreferenceChange},model:{value:(_vm.applicationFromData.useSms),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "useSms", $$v)},expression:"applicationFromData.useSms"}})]}}],null,false,1387907568)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.sms))?_c('k-form-group',{style:({
      order: _vm.getOrderIndex(_vm.workerFieldNameEnums.sms),
    }),attrs:{"id":"phoneNumberInput","label-suffix":_vm.getConfigVal(
        _vm.curPageConfigAndDefaultPageConfig,
        'phoneNumberInput',
        'labelSuffix'
      ),"label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.smsPreferenceOn ||
      _vm.isFieldRequired(_vm.workerFieldNameEnums.sms) ||
      _vm.applicationFromData.preferredContactMethod == 4
        ? 'require-mark'
        : ''}},[_c('KValidationProvider',{attrs:{"rules":{
        phone: true,
        required:
          _vm.smsPreferenceOn ||
          _vm.isFieldRequired(_vm.workerFieldNameEnums.sms) ||
          _vm.applicationFromData.preferredContactMethod == 4,
      },"slim":"","name":"phoneNumber"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-phone-input',{attrs:{"id":"phoneNumber","plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.sms),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.sms),"aria-label":_vm.$g('phoneNumber')},model:{value:(_vm.applicationFromData.sms),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "sms", $$v)},expression:"applicationFromData.sms"}})]}}],null,false,3484563227)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.usePhone))?_c('k-form-group',{style:({
      order: _vm.getOrderIndex(_vm.workerFieldNameEnums.usePhone),
    }),attrs:{"id":"phoneCallRadio","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.usePhone) ? 'require-mark' : '',"label-suffix":"phoneCall","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
        required: _vm.isFieldRequired(_vm.workerFieldNameEnums.usePhone),
      },"name":"phonePreference"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('b-form-radio-group',{staticClass:"col-form-label wapHide767",attrs:{"plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.usePhone),"disabled":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.usePhone),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.usePhone)},on:{"change":_vm.phonePreferenceChange},model:{value:(_vm.applicationFromData.usePhone),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "usePhone", $$v)},expression:"applicationFromData.usePhone"}},[_c('b-form-radio',{attrs:{"value":2}},[_vm._v(" "+_vm._s(_vm.$g("on"))+" ")]),_c('b-form-radio',{attrs:{"value":1}},[_vm._v(" "+_vm._s(_vm.$g("off"))+" ")])],1),_c('b-form-checkbox',{staticClass:"wapShow767",attrs:{"plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.usePhone),"disabled":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.usePhone),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.usePhone),"switch":"","size":"lg","value":2,"unchecked-value":1},on:{"change":_vm.phonePreferenceChange},model:{value:(_vm.applicationFromData.usePhone),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "usePhone", $$v)},expression:"applicationFromData.usePhone"}})]}}],null,false,2797181168)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.phone))?_c('k-form-group',{style:({
      order: _vm.getOrderIndex(_vm.workerFieldNameEnums.phone),
    }),attrs:{"id":"phoneNumber2Input","label-suffix":_vm.getConfigVal(
        _vm.curPageConfigAndDefaultPageConfig,
        'phoneNumber2Input',
        'labelSuffix'
      ),"label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.phonePreferenceOn ||
      _vm.isFieldRequired(_vm.workerFieldNameEnums.phone) ||
      _vm.applicationFromData.preferredContactMethod == 2
        ? 'require-mark'
        : ''}},[_c('KValidationProvider',{attrs:{"rules":{
        phone: true,
        required:
          _vm.phonePreferenceOn ||
          _vm.isFieldRequired(_vm.workerFieldNameEnums.phone) ||
          _vm.applicationFromData.preferredContactMethod == 2,
      },"slim":"","name":"phoneNumber2"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-phone-input',{attrs:{"id":"phoneNumber2","plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.phone),"aria-label":_vm.$g('phoneNumber'),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.phone)},model:{value:(_vm.applicationFromData.phone),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "phone", $$v)},expression:"applicationFromData.phone"}})]}}],null,false,3150167288)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.workPhone))?_c('k-form-group',{style:({
      order: _vm.getOrderIndex(_vm.workerFieldNameEnums.workPhone),
    }),attrs:{"label-class":_vm.phonePreferenceOn && _vm.isFieldRequired(_vm.workerFieldNameEnums.workPhone)
        ? 'require-mark'
        : '',"label-suffix":"workPhoneNumber","id":"workPhoneInput","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
        phone: true,
        required:
          _vm.phonePreferenceOn &&
          _vm.isFieldRequired(_vm.workerFieldNameEnums.workPhone),
        max: 200,
      },"slim":"","name":"workPhone"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-phone-input',{attrs:{"id":"workPhone","plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.workPhone),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.workPhone)},model:{value:(_vm.applicationFromData.workPhone),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "workPhone", $$v)},expression:"applicationFromData.workPhone"}})]}}],null,false,2964902066)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.isUseNoDisturbHours))?_c('k-form-group',{style:({
      order: _vm.getOrderIndex(_vm.workerFieldNameEnums.isUseNoDisturbHours),
    }),attrs:{"id":"doNotDisturbHoursRadio","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.isUseNoDisturbHours)
        ? 'require-mark'
        : '',"label-suffix":"doNotDisturbHours","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
        required: _vm.isFieldRequired(_vm.workerFieldNameEnums.isUseNoDisturbHours),
      },"name":"notDisturb"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('b-form-radio-group',{staticClass:"col-form-label wapHide767",attrs:{"plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.isUseNoDisturbHours),"disabled":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.isUseNoDisturbHours),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.isUseNoDisturbHours)},on:{"change":_vm.notDisturbChange},model:{value:(_vm.applicationFromData.notDisturb),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "notDisturb", $$v)},expression:"applicationFromData.notDisturb"}},[_c('b-form-radio',{attrs:{"value":2}},[_vm._v(" "+_vm._s(_vm.$g("on"))+" ")]),_c('b-form-radio',{attrs:{"value":1}},[_vm._v(" "+_vm._s(_vm.$g("off"))+" ")])],1),_c('b-form-checkbox',{staticClass:"wapShow767",attrs:{"plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.isUseNoDisturbHours),"disabled":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.isUseNoDisturbHours),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.isUseNoDisturbHours),"switch":"","size":"lg","value":2,"unchecked-value":1},on:{"change":_vm.notDisturbChange},model:{value:(_vm.applicationFromData.notDisturb),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "notDisturb", $$v)},expression:"applicationFromData.notDisturb"}})]}}],null,false,1483053680)})],1):_vm._e(),(_vm.notDisturbOn && _vm.isFieldShow(_vm.workerFieldNameEnums.noDisturbHours))?_c('k-form-group',{style:({
      order: _vm.getOrderIndex(_vm.workerFieldNameEnums.noDisturbHours),
    }),attrs:{"id":"notDisturbFromTimepicker","label-suffix":"hours","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.notDisturbOn && _vm.isFieldRequired(_vm.workerFieldNameEnums.noDisturbHours)
        ? 'require-mark'
        : '',"label-for":"noneBind"}},[_c('KValidationProvider',{staticClass:"inline-time",attrs:{"rules":{
        required:
          (_vm.notDisturbOn &&
            _vm.isFieldRequired(_vm.workerFieldNameEnums.noDisturbHours)) ||
          _vm.applicationFromData.notDisturbTo > 0,
        validateTimeFormat: true,
      },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-timepicker',{staticClass:"datepicker-time",class:{ minWidth: !_vm.edit },attrs:{"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.noDisturbHours),"plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.noDisturbHours)},model:{value:(_vm.applicationFromData.notDisturbFrom),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "notDisturbFrom", $$v)},expression:"applicationFromData.notDisturbFrom"}})]}}],null,false,4100531310)}),_c('span',{staticClass:"timePart"},[_vm._v("-")]),_c('KValidationProvider',{staticClass:"inline-time",attrs:{"rules":{
        required:
          (_vm.notDisturbOn &&
            _vm.isFieldRequired(_vm.workerFieldNameEnums.noDisturbHours)) ||
          _vm.applicationFromData.notDisturbFrom > 0,
        validateTimeFormat: true,
      },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-timepicker',{staticClass:"datepicker-time",class:{ minWidth: !_vm.edit },attrs:{"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.noDisturbHours),"plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.noDisturbHours)},model:{value:(_vm.applicationFromData.notDisturbTo),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "notDisturbTo", $$v)},expression:"applicationFromData.notDisturbTo"}})]}}],null,false,1368225827)})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.additionalPhoneNumber))?_c('k-form-group',{style:({
      order: _vm.getOrderIndex(_vm.workerFieldNameEnums.additionalPhoneNumber),
    }),attrs:{"id":"additionalPhoneNumberInput","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.additionalPhoneNumber)
        ? 'require-mark'
        : '',"label-suffix":"additionalPhoneNumber","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('KValidationProvider',{attrs:{"rules":{
        required: _vm.isFieldRequired(_vm.workerFieldNameEnums.additionalPhoneNumber),
        phone: true,
      },"slim":"","name":"additionalPhoneNumber"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('k-alert',{attrs:{"show":errors.length > 0,"variant":"warning"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('k-form-phone-input',{attrs:{"id":"additionalPhoneNumber","plaintext":!_vm.edit || _vm.isFieldViewOnly(_vm.workerFieldNameEnums.additionalPhoneNumber),"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.additionalPhoneNumber),"aria-label":_vm.$g('additionalPhoneNumber')},model:{value:(_vm.applicationFromData.additionalPhoneNumber),callback:function ($$v) {_vm.$set(_vm.applicationFromData, "additionalPhoneNumber", $$v)},expression:"applicationFromData.additionalPhoneNumber"}})]}}],null,false,3829718288)})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }