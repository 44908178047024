<template>
  <div>
    <div class="cardHead">
      {{ $g("notificationPreferences") }}
    </div>
    <div class="cardBody">
      <k-alert-msg ref="lblMsg" />
      <div>
        <KValidationObserver ref="observer">
          <k-form
            @keydown.enter="isPrevent"
            @submit.prevent="save"
            :disabled="isDisable"
          >
            <NotificationPreferences v-model="form" :edit="true" />
            <k-form-group
              label-for="noneBind"
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
              class="wapBtnCenter"
            >
              <div style="margin-top: 30px">
                <k-button
                  @click="backAction"
                  variant="utility"
                  @keydown.enter="backAction"
                  class="w-150"
                  style="margin-right: 80px"
                >
                  back
                </k-button>
                <k-button
                  type="submit"
                  variant="primary"
                  class="w-150"
                  @keydown.enter="save"
                  :disabled="isDisable"
                >
                  next
                </k-button>
              </div>
            </k-form-group>
          </k-form>
        </KValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationPreferences from "./components/notificationPreferences";

import { originDataChangeMixin, isPrevent } from "fe-infrastractures";

export default {
  props: {
    form: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  components: {
    NotificationPreferences,
  },
  mixins: [originDataChangeMixin],
  data() {
    return {
      isPrevent,
      isDisable: false,
    };
  },
  watch: {},
  mounted() {},
  created() {},
  methods: {
    backAction() {
      this.$emit("back-action", 1);
    },
    async save() {
      this.isDisable = true;
      setTimeout(() => {
        this.isDisable = false;
      }, 1000);
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.$emit("next-action", 1);
      }
    },
  },
};
</script>

