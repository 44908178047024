<template>
  <div>{{ addressMsg }}</div>
</template>

<script>
const addressTypeEnum = {
  native: 1,
  overseas: 2,
};
export default {
  props: {
    addressToCopy: {
      type: Object,
      default() {
        return null;
      },
    },
    addressformat: {
      type: String,
      default: "",
    },
    addressType: {
      type: Number,
      default: 1,
    },
    address: {
      type: Object,
      default() {
        return null;
      },
    },
    isCopy: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      addressMsg: "",
    };
  },
  methods: {
    formatAddressFunc0(tempAddress) {
      return `${tempAddress.addressLine1}${
        tempAddress.addressLine1 &&
        (tempAddress.addressLine2 ||
          tempAddress.addressLine3 ||
          tempAddress.overseaCountry)
          ? ", "
          : ""
      }${tempAddress.addressLine2}${
        tempAddress.addressLine2 &&
        (tempAddress.addressLine3 || tempAddress.overseaCountry)
          ? ", "
          : ""
      }${tempAddress.addressLine3}${
        tempAddress.addressLine3 && tempAddress.overseaCountry ? ", " : ""
      }${tempAddress.overseaCountry ? tempAddress.overseaCountry : ""}`;
    },
    formatAddress() {
      if (!this.address) {
        return "";
      }
      let tempAddress = { ...this.address };
      if (this.addressType === addressTypeEnum.native) {
        return this.handleFormatAddress(this.addressformat, tempAddress);
      } else if (this.addressType === addressTypeEnum.overseas) {
        if (this.isCopy) {
          return this.handleFormatAddress(
            this.addressformat,
            this.addressToCopy
          );
        } else {
          return this.formatAddressFunc0(tempAddress);
        }
      } else {
        return this.handleFormatAddress(this.addressformat, tempAddress);
      }
    },
    handleFormatAddress(format, address) {
      if (!format) {
        return "";
      }
      let result = format;
      let formatObj = {
        $Unit$: address.unit ?? "",
        "$Street Number$": address.streetNumber ?? "",
        "$Street Name$": address.streetName ?? "",
        "$Street Type$": address.streetType ?? "",
        $City$: address.city ?? "",
        $State$: address.state ?? "",
        "$Postal Code$": address.postcode ?? "",
        $Country$: address.country ?? "",
        "$Street Fraction$": address.streetFraction ?? "",
        "$Street Pre$": address.streetPre ?? "",
        "$Street Post$": address.streetPost ?? "",
        $Building$: address.building ?? "",
        $Apartment$: address.apartment ?? "",
        "$,$": ",",
        "$, $": ",",
      };
      Object.keys(formatObj).forEach((key) => {
        result = result.replace(key, formatObj[key]);
      });
      const regex = /\$|\//g;
      if (!address.unit) {
        return result.replace(regex, "");
      }
      return result.replace(/\$/g, "");
    },
  },
  mounted() {
    this.addressMsg = this.formatAddress();
    this.addressMsg = this.addressMsg.replace(/\s(?=,)/g, "");
    this.addressMsg = this.addressMsg.replace(/\s+/g, " ");
  },
};
</script>

